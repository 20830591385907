import '/assets/styles/layouts/simple.scss';
import PropTypes from 'prop-types';

// Sections
import Header from '/views/sections/header'
import Footer from '/views/sections/footer'

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator'

const BaseLayout = ({ children, pageClass }) => {
	const { activeRoute } = useNavigator();

	const pageTitle = activeRoute?.title || activeRoute?.head?.title;

	return (
		<div className={`page layout-simple ${pageClass ? 'page-' + pageClass : ''}`}>
			<Header />
			<div className="wrapper layout-simple-wrap">
				{pageTitle &&
					<h1 className="layout-simple-header">{pageTitle}</h1>
				}
				{children}
			</div>
			<Footer />
		</div>
	)
}

BaseLayout.propTypes = {
	children: PropTypes.node,
	pageClass: PropTypes.string,
}

export default BaseLayout